
















































































































































.containerMap {
  height: 700px;
  width: 815px;
  .zones-map {
    height: 84%;
  }
}

.rowclass {
  height: 50px;
}
