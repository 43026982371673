











































































.v-btn:not(.v-btn--outlined).danger {
  color: #ffffff;
}
