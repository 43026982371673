


























































.v-btn:not(.v-btn--outlined).danger {
  color: #ffffff;
}
.text-center{
  font-size: 1.15rem;
}
